// ** Initial State
const initialState = {
  countries: [],
  shareholderTypes: [],
  shareTypes: [],
  companyTypes: [],
  serviceCharges: [],
  virtualOffice: {},
  directors: [],
  totalOrderNotificationCount: 0,
  totalCompanyNotificationCount: 0
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMMON_DATA':
      return { ...state, ...action.data }
    case 'SET_COUNTRIES':
      return { ...state, countries: action.data }
    case 'SET_SHAREHOLDER_TYPES':
      return { ...state, shareholderTypes: action.data }
    case 'SET_SHARE_TYPES':
      return { ...state, shareTypes: action.data }
    case 'SET_COMPANY_TYPES':
      return { ...state, companyTypes: action.data }
    case 'TOTAL_ORDER_NOTIFICATION_COUNT':
      return { ...state, totalOrderNotificationCount: action.data }
    case 'TOTAL_COMPANY_NOTIFICATION_COUNT':
      return { ...state, totalCompanyNotificationCount: action.data }
    default:
      return state
  }
}

export default commonReducer
